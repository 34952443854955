import React, { useState } from 'react';
import pdfIcon from 'images/icon/pdf-icon.svg';
import downloadIcon from 'images/icon/download-orange-ic.svg';
import Tabs from '../../../Tabs';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

const dataEn = [
  {
    title: 'Financial Statements Quarter III 2023',
    path: 'FINAL_REPORT_PT_MULTI_GARAM_UTAMA_30_SEPT_2023.pdf',
  },
  {
    title: 'Financial Statements Quarter I 2024',
    path: 'FSFOLK31Maret2024.pdf',
  },
  {
    title: 'Financial Statements Quarter II 2024',
    path: 'LaporanKeuanganTengahTahunFOLK2024.pdf',
  },
];
const dataIn = [
  {
    title: 'Laporan Keuangan Triwulan III Tahun 2023',
    path: 'FINAL_REPORT_PT_MULTI_GARAM_UTAMA_30_SEPT_2023.pdf',
  },
  {
    title: 'Laporan Keuangan Triwulan I Tahun 2024',
    path: 'FSFOLK31Maret2024.pdf',
  },
  {
    title: 'Laporan Keuangan Triwulan II Tahun 2024',
    path: 'LaporanKeuanganTengahTahunFOLK2024.pdf',
  },
];

const SectionFinanceInformation = ({ files }) => {
  const { t } = useTranslation('about-us');
  const loc = useLocation();
  const currentPath = loc.href;
  const isEnglish = currentPath.includes('/en/');
  const tabTitles = isEnglish ? ['Quarter', 'Annual'] : ['Kuartal', 'Tahunan'];
  const [tabActive, setTabActive] = useState(tabTitles[0]);
  const data = isEnglish ? dataEn : dataIn;

  return (
    <div className="min-h-[60vh] py-3">
      <Tabs
        listOfTab={tabTitles}
        tabSelected={tabActive}
        handleSelectTab={(e) => setTabActive(e)}
      />
      {tabActive === tabTitles[0] && (
        <div className="mt-6 pl-6">
          <div className="border-b py-3 mb-2">
            <h2 className="text-base md:text-2xl font-medium">
              {t('title kuartal')}
            </h2>
          </div>
          {data?.map((item, idx) => (
            <div
              key={idx.toString()}
              className="flex justify-between py-3 md:py-2 cursor-pointer"
            >
              <div className="flex items-center gap-3">
                <img src={pdfIcon} alt="pdf-icon" className="w-4" />
                <a href={`/pdf/id/${item.path}`} target="_blank">
                  <p className="text-sm md:text-base font-[200] cursor-pointer">
                    {item.title}
                  </p>
                </a>
              </div>
              <img
                src={downloadIcon}
                alt="icon-download"
                className="block md:hidden"
              />
            </div>
          ))}
        </div>
      )}
      {tabActive === tabTitles[1] && (
        <div className="mt-6 pl-6">
          <div className="border-b py-3 mb-2">
            <h2 className="text-base md:text-2xl font-medium">
              {t('title tahunan')}
            </h2>
          </div>
          <div className="flex justify-between py-3 md:py-2 cursor-pointer">
            <div className="flex items-center gap-3">
              <img src={pdfIcon} alt="pdf-icon" className="w-4" />
              <a href={`/pdf/id/laporan_keuangan.pdf`} target="_blank">
                <p className="text-sm md:text-base font-[200] cursor-pointer">
                  Final Report PT Multi Garam Utama Tbk 31 Des 2023
                </p>
              </a>
            </div>
            <img
              src={downloadIcon}
              alt="icon-download"
              className="block md:hidden"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionFinanceInformation;
